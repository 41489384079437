import React from 'react'

function FilesIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="#142D63"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.6">
        <path d="M16.6922 5.68281L13.5672 2.55781C13.5091 2.49979 13.4402 2.45378 13.3643 2.42241C13.2884 2.39105 13.2071 2.37494 13.125 2.375H6.875C6.54348 2.375 6.22554 2.5067 5.99112 2.74112C5.7567 2.97554 5.625 3.29348 5.625 3.625V4.875H4.375C4.04348 4.875 3.72554 5.0067 3.49112 5.24112C3.2567 5.47554 3.125 5.79348 3.125 6.125V17.375C3.125 17.7065 3.2567 18.0245 3.49112 18.2589C3.72554 18.4933 4.04348 18.625 4.375 18.625H13.125C13.4565 18.625 13.7745 18.4933 14.0089 18.2589C14.2433 18.0245 14.375 17.7065 14.375 17.375V16.125H15.625C15.9565 16.125 16.2745 15.9933 16.5089 15.7589C16.7433 15.5245 16.875 15.2065 16.875 14.875V6.125C16.8751 6.0429 16.859 5.96159 16.8276 5.88572C16.7962 5.80985 16.7502 5.7409 16.6922 5.68281ZM10.625 15.5H6.875C6.70924 15.5 6.55027 15.4342 6.43306 15.3169C6.31585 15.1997 6.25 15.0408 6.25 14.875C6.25 14.7092 6.31585 14.5503 6.43306 14.4331C6.55027 14.3158 6.70924 14.25 6.875 14.25H10.625C10.7908 14.25 10.9497 14.3158 11.0669 14.4331C11.1842 14.5503 11.25 14.7092 11.25 14.875C11.25 15.0408 11.1842 15.1997 11.0669 15.3169C10.9497 15.4342 10.7908 15.5 10.625 15.5ZM10.625 13H6.875C6.70924 13 6.55027 12.9342 6.43306 12.8169C6.31585 12.6997 6.25 12.5408 6.25 12.375C6.25 12.2092 6.31585 12.0503 6.43306 11.9331C6.55027 11.8158 6.70924 11.75 6.875 11.75H10.625C10.7908 11.75 10.9497 11.8158 11.0669 11.9331C11.1842 12.0503 11.25 12.2092 11.25 12.375C11.25 12.5408 11.1842 12.6997 11.0669 12.8169C10.9497 12.9342 10.7908 13 10.625 13ZM15.625 14.875H14.375V8.625C14.3751 8.5429 14.359 8.46159 14.3276 8.38572C14.2962 8.30985 14.2502 8.2409 14.1922 8.18281L11.0672 5.05781C11.0091 4.99979 10.9402 4.95378 10.8643 4.92241C10.7884 4.89105 10.7071 4.87494 10.625 4.875H6.875V3.625H12.8664L15.625 6.38359V14.875Z" />
      </g>
    </svg>
  )
}

export default FilesIcon
