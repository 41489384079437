import React from 'react'
import { SchoolCardTypeEnum } from './types'

export const Wrapper = ({
  children,
  isLink,
  ...restProps
}: React.PropsWithChildren<{
  className?: string
  isLink: boolean
  href?: string
  target?: string
  onClick?: () => void
}>) => React.createElement(isLink ? 'a' : 'div', { ...restProps }, children)
