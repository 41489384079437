import { Tab } from '@headlessui/react'
import React from 'react'

interface PageTabProps extends Record<string, unknown> {
  children: React.ReactNode
  className?: string
}

export const PageTab = ({ children, className, ...props }: PageTabProps) => {
  return (
    <Tab
      className={({ selected }) =>
        `${
          selected ? 'border-b-2 border-b-blue text-darkblue' : 'border-b-2 border-b-transparent'
        } main-transition-colors w-fit px-4 pb-6 pt-6 outline-none sm:px-5 ${className || ''}`
      }
      {...props}
    >
      <span className="whitespace-nowrap font-inter font-bold sm:text-[17px]">{children}</span>
    </Tab>
  )
}
