import useSWR from 'swr'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { MEMBERSHIP_API } from '../api/membership-api'
import {
  SchoolCoursesOfCourseBundleApiInterface,
  SchoolEnrollmentId,
} from '../types/school-enrollments-interface'

interface UseEnrollmentsInterface {
  schoolEnrollmentId: SchoolEnrollmentId | null
}

export function useCoursesOfCourseBundle({ schoolEnrollmentId }: UseEnrollmentsInterface) {
  const { user } = useUser()

  const { fetcher: listFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    SchoolCoursesOfCourseBundleApiInterface
  >({ method: RequestMethodsEnum.get })

  const { data: coursesOfCourseBundle } = useSWR<SchoolCoursesOfCourseBundleApiInterface>(
    () =>
      user && schoolEnrollmentId && `${MEMBERSHIP_API}/enrollments/${schoolEnrollmentId}/bundle`,
    listFetcher,
  )

  return { coursesOfCourseBundle }
}
