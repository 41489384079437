import React from 'react'

const CourseCardSkeleton = ({ className }: { className?: string }) => (
  <div className={`h-[200px] animate-pulse rounded-2xl bg-gray-600 ${className}`} />
)

export const CourseCardsSkeleton = () => {
  return (
    <>
      <CourseCardSkeleton />
      <CourseCardSkeleton />
      <CourseCardSkeleton />
    </>
  )
}
