import useSWR from 'swr'
import { getPaginationQueryString } from 'shared/api/utils/get-pagination-query-string'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { SwrCountInterface, SwrPaginationInterface } from 'shared/types/swr-pagination-interface'
import { MEMBERSHIP_API } from '../api/membership-api'
import { SchoolExploreCoursesApiInterface } from '../types/school-explore-courses-interface'

interface UseCampaignsInterface extends SwrPaginationInterface {}

export function useExploreCourses({ limit, pagination }: UseCampaignsInterface) {
  const { user } = useUser()
  const { fetcher: listFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    SchoolExploreCoursesApiInterface
  >({ method: RequestMethodsEnum.get })
  const { fetcher: countFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    SwrCountInterface
  >({
    method: RequestMethodsEnum.get,
  })

  const { data: exploreCourses, mutate } = useSWR<SchoolExploreCoursesApiInterface>(
    () =>
      user &&
      `${MEMBERSHIP_API}/explore/list?${getPaginationQueryString({
        limit,
        pagination,
      })}`,
    listFetcher,
  )

  const { data: counter } = useSWR<SwrCountInterface>(() => {
    user && `${MEMBERSHIP_API}/count?pagination[limit]`
  }, countFetcher)

  return { exploreCourses, mutate, counter }
}
