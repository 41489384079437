import React from 'react'
import { useTranslation } from 'next-i18next'
import FolderIcon from 'shared/icons/folder-icon'

export interface CourseBundleBadgeProps {
  className?: string
  onClick: () => void
}

export const CourseBundleBadge = ({ className, onClick }: CourseBundleBadgeProps) => {
  const { t } = useTranslation()

  return (
    <div
      onClick={onClick}
      className={`flex cursor-pointer gap-1 rounded-full bg-white px-4 py-1 text-sm font-semibold leading-[23px] text-darkblue ${className || ''}`}
    >
      <FolderIcon />
      {t('school.course_bundle_badge')}
    </div>
  )
}
