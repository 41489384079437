import React from 'react'
import useMediaQuery from 'shared/hooks/use-media-query'
import { limitObj_12_24_48 } from 'shared/route-query/constants/pagination-limit-array'
import { usePagination } from 'shared/route-query/hooks/use-pagination'
import EmptyList from 'modules/profile/manage-suscriptions/components/table/empty-list'
import TableLimitPagination from 'modules/profile/manage-suscriptions/components/table/table-limit-pagination'
import { useExploreCourses } from '../hooks/use-explore-courses'
import { SchoolCard } from './school-card'
import { SchoolCardsSkeleton } from './school-card/school-cards-skeleton'
import { SchoolCardTypeEnum } from './school-card/types'

export const ExploreCoursesTab = () => {
  const { limit, setLimit, pagination, setPagination } = usePagination({
    isStaticUrl: true,
    initLimit: limitObj_12_24_48.short,
  })
  const { exploreCourses } = useExploreCourses({ limit, pagination })

  const { isSM } = useMediaQuery()

  return (
    <div>
      <div
        className={
          'mt-9 grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-5 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4'
        }
      >
        {exploreCourses ? (
          exploreCourses.items.length ? (
            exploreCourses.items.map(data => (
              <SchoolCard
                isSM={isSM}
                data={data}
                type={SchoolCardTypeEnum.explore_course}
                key={data.id}
              />
            ))
          ) : (
            <EmptyList className="col-span-full bg-transparent" />
          )
        ) : (
          <SchoolCardsSkeleton />
        )}
      </div>
      <div className="mt-4 flex justify-end gap-6 md:mt-5 lg:mt-6">
        <TableLimitPagination
          data={exploreCourses}
          limiter={{ limit, setLimit }}
          paginator={{
            pagination,
            setPagination,
            hasMore: exploreCourses?.hasMore,
          }}
          limitObj={limitObj_12_24_48}
        />
      </div>
    </div>
  )
}
