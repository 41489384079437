import React from 'react'

function SearchIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      stroke="#142D63"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2107 7.17079C14.2107 10.5788 11.448 13.3416 8.03993 13.3416C4.6319 13.3416 1.86914 10.5788 1.86914 7.17079C1.86914 3.76276 4.6319 1 8.03993 1C11.448 1 14.2107 3.76276 14.2107 7.17079Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7538 14.8847L12.3984 11.5293"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SearchIcon
