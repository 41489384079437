import {
  SchoolCourseBundleEnrollmentsDataInterface,
  SchoolCourseEnrollmentsDataInterface,
  SchoolEnrollmentTypeEnum,
} from 'modules/school/types/school-enrollments-interface'
import { SchoolExploreCoursesInterface } from 'modules/school/types/school-explore-courses-interface'

export enum SchoolCardTypeEnum {
  course = 'course',
  bundle = 'bundle',
  explore_course = 'explore_course',
}

export const schoolEnrollmentTypeEnumToSchoolCardTypeEnum = {
  [SchoolEnrollmentTypeEnum.course]: SchoolCardTypeEnum.course,
  [SchoolEnrollmentTypeEnum.bundle]: SchoolCardTypeEnum.bundle,
}

export type SchoolCardProps = { isSM: boolean } & (
  | {
      data: SchoolCourseEnrollmentsDataInterface
      type: SchoolCardTypeEnum.course
      onOpenCourseBundleModal?: never
    }
  | {
      data: SchoolCourseBundleEnrollmentsDataInterface
      type: SchoolCardTypeEnum.bundle
      onOpenCourseBundleModal: () => void
    }
  | {
      data: SchoolExploreCoursesInterface
      type: SchoolCardTypeEnum.explore_course
      onOpenCourseBundleModal?: never
    }
)
