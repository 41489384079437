import { TabGroup } from '@headlessui/react'
import React, { Fragment } from 'react'

interface PageTabGroupProps extends Record<string, unknown> {
  className?: string
}

export const PageTabGroup = ({ className, ...props }: PageTabGroupProps) => {
  return (
    <div className={`w-full ${className || ''}`}>
      <TabGroup as={Fragment} {...props} />
    </div>
  )
}
