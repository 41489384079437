import React from 'react'

function FolderIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M17.5 5H12.0836L9.91641 3.375C9.69972 3.21332 9.43676 3.12567 9.16641 3.125H5.625C5.29348 3.125 4.97554 3.2567 4.74112 3.49112C4.5067 3.72554 4.375 4.04348 4.375 4.375V5.625H3.125C2.79348 5.625 2.47554 5.7567 2.24112 5.99112C2.0067 6.22554 1.875 6.54348 1.875 6.875V15.625C1.875 15.9565 2.0067 16.2745 2.24112 16.5089C2.47554 16.7433 2.79348 16.875 3.125 16.875H15.0695C15.3825 16.8746 15.6825 16.7501 15.9038 16.5288C16.1251 16.3075 16.2496 16.0075 16.25 15.6945V14.375H17.5695C17.8825 14.3746 18.1825 14.2501 18.4038 14.0288C18.6251 13.8075 18.7496 13.5075 18.75 13.1945V6.25C18.75 5.91848 18.6183 5.60054 18.3839 5.36612C18.1495 5.1317 17.8315 5 17.5 5ZM17.5 13.125H16.25V8.75C16.25 8.41848 16.1183 8.10054 15.8839 7.86612C15.6495 7.6317 15.3315 7.5 15 7.5H9.58359L7.41641 5.875C7.19972 5.71332 6.93676 5.62567 6.66641 5.625H5.625V4.375H9.16641L11.3336 6C11.5503 6.16168 11.8132 6.24933 12.0836 6.25H17.5V13.125Z"
          fill="#00A0FF"
        />
      </g>
    </svg>
  )
}

export default FolderIcon
