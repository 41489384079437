import { TabPanel } from '@headlessui/react'
import React from 'react'

interface PageTabPanelProps extends Record<string, unknown> {
  className?: string
}

export const PageTabPanel = ({ className, ...props }: PageTabPanelProps) => {
  return (
    <div className={`${className || ''}`}>
      <TabPanel {...props} />
    </div>
  )
}
