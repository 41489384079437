import React, { useState } from 'react'
import useMediaQuery from 'shared/hooks/use-media-query'
import { limitObj_12_24_48 } from 'shared/route-query/constants/pagination-limit-array'
import { useFilterPagination } from 'shared/route-query/hooks/use-filter-pagination'
import EmptyList from 'modules/profile/manage-suscriptions/components/table/empty-list'
import TableLimitPagination from 'modules/profile/manage-suscriptions/components/table/table-limit-pagination'
import {
  CourseEnrollmentsFilterInterface,
  SchoolEnrollmentTypeEnum,
  SchoolEnrollmentsApiInterface,
} from '../types/school-enrollments-interface'
import { SchoolCard } from './school-card'
import { SchoolCardsSkeleton } from './school-card/school-cards-skeleton'
import { SchoolCardProps, schoolEnrollmentTypeEnumToSchoolCardTypeEnum } from './school-card/types'
import SchoolCourseBundleModal, {
  SchoolCourseBundleModalModalProps,
} from './school-course-bundle-modal'

export interface EnrollmentsTabProps
  extends Pick<
    ReturnType<typeof useFilterPagination>,
    'limit' | 'setLimit' | 'pagination' | 'setPagination'
  > {
  enrollments: SchoolEnrollmentsApiInterface | undefined
  filter: CourseEnrollmentsFilterInterface
  filterComponent: React.ReactNode
}

export const EnrollmentsTab = ({
  enrollments,
  limit,
  setLimit,
  pagination,
  setPagination,
  filterComponent,
}: EnrollmentsTabProps) => {
  const [courseBundleModalState, setCourseBundleModalState] = useState<
    Omit<SchoolCourseBundleModalModalProps, 'onClose'>
  >({
    isOpened: false,
    schoolEnrollmentId: null,
    name: null,
    imagePath: null,
  })

  const { isSM } = useMediaQuery()

  return (
    <div>
      {filterComponent}
      <div
        className={
          'mt-9 grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-5 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4'
        }
      >
        {enrollments ? (
          enrollments.items.length ? (
            enrollments.items.map(({ id, data, type }) => (
              <SchoolCard
                {...({
                  isSM,
                  data,
                  type: schoolEnrollmentTypeEnumToSchoolCardTypeEnum[type],
                  ...(type === SchoolEnrollmentTypeEnum.bundle && {
                    onOpenCourseBundleModal: () =>
                      setCourseBundleModalState({
                        isOpened: true,
                        schoolEnrollmentId: id,
                        name: data.name,
                        imagePath: data.image?.path || null,
                      }),
                  }),
                } as SchoolCardProps)}
                key={id}
              />
            ))
          ) : (
            <EmptyList className="col-span-full bg-transparent" />
          )
        ) : (
          <SchoolCardsSkeleton />
        )}
      </div>
      <div className="mt-4 flex justify-end gap-6 md:mt-5 lg:mt-6">
        <TableLimitPagination
          data={enrollments}
          limiter={{ limit, setLimit }}
          paginator={{
            pagination,
            setPagination,
            hasMore: enrollments?.hasMore,
          }}
          limitObj={limitObj_12_24_48}
        />
      </div>
      <SchoolCourseBundleModal
        onClose={() =>
          setCourseBundleModalState({
            isOpened: false,
            schoolEnrollmentId: null,
            name: null,
            imagePath: null,
          })
        }
        {...courseBundleModalState}
      />
    </div>
  )
}
