import { useCallback, useEffect, useRef, useState } from 'react'
import Router, { useRouter } from 'next/router'
import { usePaginationQuery } from 'shared/route-query/hooks/use-pagination-query'
import { getPaginationQuery } from 'shared/route-query/utils/get-pagination-query'
import { setPaginationQuery } from 'shared/route-query/utils/set-pagination-query'
import { LimitType, OrderEnum, PaginationInterface } from 'shared/types/swr-pagination-interface'
import { limitObj_10_25_50 } from '../constants/pagination-limit-array'

const paginationKeys = ['order', 'limit', 'startFromId']

export const usePagination = ({
  isStaticUrl,
  initLimit = limitObj_10_25_50.short,
}: {
  isStaticUrl?: boolean
  initLimit?: LimitType
}) => {
  const { isReady, query } = useRouter()

  const queryRef = useRef(query)

  useEffect(() => {
    queryRef.current = query
  }, [query])

  const { limit: limitFromRouter, startFromId, order } = usePaginationQuery()

  const [limit, setLimit] = useState<LimitType>(limitFromRouter || initLimit)

  const [pagination, setPagination] = useState<PaginationInterface>({
    startFromId: startFromId,
    order: OrderEnum.next,
    staticUrl: isStaticUrl,
  })

  useEffect(() => {
    if (!isReady) return
    setPaginationQuery({ setPagination, setLimit, limit: limitFromRouter, order, startFromId })
  }, [isReady, limitFromRouter, order, startFromId])

  useEffect(() => {
    if (isStaticUrl || !isReady) return

    const paginationQuery = getPaginationQuery({ limit, pagination, initLimit })

    const baseQueryKey = (Object.keys(queryRef.current) as Array<string>).find(
      el => !paginationKeys.includes(el),
    )
    const baseQuery = baseQueryKey ? { [baseQueryKey]: queryRef.current[baseQueryKey] } : {}

    const timeout = setTimeout(() => {
      Router.replace(
        {
          query: {
            ...baseQuery,
            ...paginationQuery,
          },
        },
        undefined,
        { shallow: true },
      )
    })

    return function cleanUp() {
      clearTimeout(timeout)
    }
  }, [isStaticUrl, limit, isReady, pagination, initLimit])

  const clearPagination = useCallback(() => {
    setPagination({ startFromId: undefined, order: OrderEnum.next })
    setLimit(initLimit)
  }, [initLimit])

  return { limit, setLimit, pagination, setPagination, clearPagination }
}
