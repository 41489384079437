import { TabList } from '@headlessui/react'
import React from 'react'

interface PageTabListProps extends Record<string, unknown> {
  className?: string
  buttonBar?: React.ReactNode
}

export const PageTabList = ({ className, buttonBar, ...props }: PageTabListProps) => {
  return (
    <div
      className={`mb-5 ml-[min(-20px,calc(-1*(100vw-1536px)/2-20px))] flex w-screen items-center justify-between bg-white pl-[max(20px,calc((100vw-1536px)/2+20px))] pr-10 lg:pr-[max(20px,calc((100vw-1536px)/2+20px))] ${className || ''}`}
    >
      <TabList {...props} />
      {buttonBar}
    </div>
  )
}
