import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import FormInputSearch from 'shared/components/form-input-search'
import PrimaryButton from 'shared/components/primary-button'
import SearchIcon from 'shared/icons/search-icon'
import {
  CourseEnrollmentsFilterInterface,
  SchoolCommonEnrollmentsDataInterface,
} from '../types/school-enrollments-interface'

export const defaultFilter: CourseEnrollmentsFilterInterface = {
  searchBy: '',
}

export interface EnrollmentsTabFilterProps {
  className?: string
  inputClassName?: string
  filter: CourseEnrollmentsFilterInterface
  setFilter: (arg: React.SetStateAction<CourseEnrollmentsFilterInterface>) => void
}

export const EnrollmentsTabFilter = ({
  className,
  inputClassName,
  filter,
  setFilter,
}: EnrollmentsTabFilterProps) => {
  const [nameFilter, setNameFilter] = useState<SchoolCommonEnrollmentsDataInterface['name']>(
    filter.searchBy,
  )

  const { t } = useTranslation()

  return (
    <form className={`justify-end gap-5 ${className || 'flex'}`}>
      <FormInputSearch
        key={'name-filter'}
        inputClassName={inputClassName}
        placeholder={t('global.name')}
        onClear={() => setNameFilter('')}
        value={nameFilter}
        onChange={e => setNameFilter(e.target.value)}
      />
      <PrimaryButton
        type="submit"
        onClick={e => {
          e.preventDefault()
          setFilter(prev => ({ ...prev, searchBy: nameFilter }))
        }}
        className="flex items-center"
      >
        <SearchIcon className="h-[15px] w-[15px]" stroke="white" />
        <span className="hidden font-bold sm:block"> {t('global.search')}</span>
      </PrimaryButton>
    </form>
  )
}
