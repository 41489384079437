import { DataFile } from 'shared/types/datafile-type'
import { DataWithPaginationInterface } from 'modules/profile/manage-suscriptions/types/manage-subscriptions-interface'

export type SchoolEnrollmentId = number

export enum SchoolEnrollmentTypeEnum {
  course = 'course',
  bundle = 'bundle',
}

export interface SchoolCommonEnrollmentsDataInterface {
  name: string
  instructorName: string | null
  description: string | null
  image: DataFile | null
  progress: number
}

export interface SchoolCourseEnrollmentsDataInterface extends SchoolCommonEnrollmentsDataInterface {
  totalLecturesCount: number
  completedLecturesCount: number
  accessFrom: string | null
  resumeUrl: string
}

export interface SchoolCourseBundleEnrollmentsDataInterface
  extends SchoolCommonEnrollmentsDataInterface {
  totalCoursesCount: number
  completedCoursesCount: number
}

export type SchoolEnrollmentsInterface = {
  id: SchoolEnrollmentId
} & (
  | {
      type: SchoolEnrollmentTypeEnum.course
      data: SchoolCourseEnrollmentsDataInterface
    }
  | {
      type: SchoolEnrollmentTypeEnum.bundle
      data: SchoolCourseBundleEnrollmentsDataInterface
    }
)

export interface SchoolEnrollmentsApiInterface
  extends DataWithPaginationInterface<SchoolEnrollmentsInterface> {}

export type SchoolCoursesOfCourseBundleApiInterface = SchoolCourseEnrollmentsDataInterface[]

export interface CourseEnrollmentsFilterInterface {
  searchBy: string
}
