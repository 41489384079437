import React from 'react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'

export interface EmptyListProps {
  className?: string
}

const EmptyList = ({ className }: EmptyListProps) => {
  const { t } = useTranslation()
  return (
    <div
      className={`flex w-full flex-col items-center justify-center gap-3 rounded-lg bg-white py-10 ${
        className || ''
      }`}
    >
      <Image width={200} height={200} alt={'list-empty'} src={'/images/empty-list-logo.png'} />
      <div className={`flex flex-col items-center`}>
        <span className={'text-base text-darkblue'}>{t('global.list.empty_title')}</span>
        <span className={'text-base text-darkblue'}>{t('global.list.empty_list')}</span>
      </div>
    </div>
  )
}

export default EmptyList
