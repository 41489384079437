import { useTranslation } from 'next-i18next'
import BaseModal from 'shared/components/base-modal'
import CloseIcon from 'shared/icons/close-icon'
import { DataFile } from 'shared/types/datafile-type'
import { useCoursesOfCourseBundle } from 'modules/school/hooks/use-courses-of-course-bundle'
import defaultСourseСover from '../../../../../public/images/default-course-cover.png'
import { SchoolEnrollmentId } from '../../types/school-enrollments-interface'
import { SCHOOL_CARD_GRADIENT_OVERLAY_CLASSNAME } from '../constants/school-card-gradient-overlay-classname'
import { CourseCard } from './course-card'
import { CourseCardsSkeleton } from './course-card-skeleton'

export interface SchoolCourseBundleModalModalProps {
  isOpened: boolean
  onClose: () => void
  schoolEnrollmentId: SchoolEnrollmentId | null
  name: string | null
  imagePath: DataFile['path'] | null
}

function SchoolCourseBundleModal({
  isOpened,
  onClose,
  schoolEnrollmentId,
  name,
  imagePath,
}: SchoolCourseBundleModalModalProps) {
  const { t } = useTranslation()

  const { coursesOfCourseBundle } = useCoursesOfCourseBundle({ schoolEnrollmentId })

  return (
    <BaseModal className="relative !rounded-2xl bg-lightblue" opened={isOpened} onClose={onClose}>
      <CloseIcon
        onClick={onClose}
        className={`absolute right-2 top-2 z-10 h-8 w-8 cursor-pointer rounded-full bg-white p-2 lg:left-full lg:right-[unset] lg:top-0 lg:ml-3`}
      />
      <div className={`relative ${SCHOOL_CARD_GRADIENT_OVERLAY_CLASSNAME}`}>
        <img
          className="h-full min-h-[200px] w-full rounded-t-2xl object-cover sm:h-[300px]"
          src={imagePath || defaultСourseСover.src}
          alt=""
        />
        <h3 className="absolute bottom-3 left-3 text-xl font-bold text-white sm:bottom-8 sm:left-8 sm:text-2xl">
          {name}
        </h3>
      </div>
      <div className="p-5 md:p-8">
        <div className="flex justify-between">
          <span className="text-lg font-bold text-darkblue sm:text-3xl">
            {t('school.course_bundle_modal.courses_label')}
          </span>
        </div>
        <div className={'mt-4 flex flex-col gap-12 sm:mt-9'}>
          {coursesOfCourseBundle ? (
            coursesOfCourseBundle?.map((data, index) => <CourseCard key={index} data={data} />)
          ) : (
            <CourseCardsSkeleton />
          )}
        </div>
      </div>
    </BaseModal>
  )
}

export default SchoolCourseBundleModal
